<template>
  <div v-if="accountInfo">
    <div style="position: fixed; width: 220px;min-height: 100vh;background-color: #30353f;">

      <div style="font-size:14px; line-height: 50px;color: white;font-weight: bold;text-align: center;">
        销售系统
      </div>
      <div class="no-scroll-bar" style="max-height: calc(100vh - 60px)">
        <app-menu :items="menuItems" />
      </div>
    </div>
    
    <div style="margin-left: 220px;">
      <div class="nav-bar">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="o in breadcrumb" :key="o">{{o}}</el-breadcrumb-item>
        </el-breadcrumb>

        <el-dropdown @command="handleCommand($event)">
          <span class="el-dropdown-link" style="cursor:pointer;">
            {{accountInfo.user.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="updatePassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div style="padding: 0 15px 15px 15px;">
        <router-view />
      </div>
    </div>
    <update-password-form ref="updatePasswordForm" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import AppMenu from '@/components/Menu'
import UpdatePasswordForm from './UpdatePasswordForm';

export default {
  components: { AppMenu, UpdatePasswordForm },
  data() {
    return {
    }
  },

  computed: {
    breadcrumb() {
      const path = _.last(this.$route.matched).path;
      return _.map(_.find(this.menuList, { path })?.breadcrumb || [], o => _.isFunction(o) ? o(this.$store.state) : o)
    },

    ...mapState(['accountInfo', 'menuList', 'menuItems']),
  },

  methods: {
    async logout() {
      try {
        await this.$api.execute('account.logout');
        this.$store.commit('setAccountInfo', null);
        this.$router.push(`/login?redirect=${this.$route.path}`)
      } catch(e) {
        this.$message.error(e.message);
      }
    },
    updatePassword() {
      this.$refs.updatePasswordForm.show({ }, {
        title: '修改密码',
        success: () => {
          this.$message.success('密码修改成功，请重新登录')
          this.$store.commit('setAccountInfo', null);
          this.$router.push(`/login?redirect=${this.$route.path}`)
        }
      });
    },
    handleCommand(command) {
      command && this[command] && this[command]()
    },
  }
}
</script>

<style lang="scss">
.no-scroll-bar {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.nav-bar {
  padding: 0 15px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 1px 4px #00152914;
}
</style>
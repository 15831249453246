<template>
  <el-menu
    router
    background-color="#30353f"
    text-color="#ffffff"
    style="border-right: none;"
    >
    <menu-item v-for="o in items" :key="o.index" :data="o" />
  </el-menu>
</template>

<script>
import MenuItem from './MenuItem';
export default {
  components: { MenuItem },
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  }
}
</script>
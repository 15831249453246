<template>
  <el-submenu v-if="hasChildren" :index="data.path">
    <template slot="title">
      <i v-if="data.icon" :class="data.icon"></i>
      <span>{{data.title}}</span>
    </template>
    <menu-item v-for="o in data.children" :key="o.path" :data="o" />    
  </el-submenu>
  <el-menu-item v-else :index="data.path">
    <i v-if="data.icon" :class="data.icon"></i>
    <span>{{data.title}}</span>
  </el-menu-item>

</template>

<script>
import MenuItem from './MenuItem';
export default {
  name: 'menu-item',
  components: { MenuItem },
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasChildren() {
      return !!this.data.children && this.data.children.length > 0
    }
  }
}
</script>